import { useEffect } from 'react';
import { Spinner } from 'bv-components';
import { useHistory } from 'react-router-dom';
import { useDepositLink } from 'bv-hooks';

const CashierContainer = () => {
  const history = useHistory();
  const redirection = useDepositLink();

  useEffect(() => {
    history.push(redirection);
  }, []);

  return <Spinner />;
};

export default CashierContainer;
