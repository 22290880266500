import Address from 'Registration/components/fields/address/address_container';
import AddressFinder from 'Registration/components/fields/address_finder';
import AsiaName from 'Registration/components/fields/asia_name/asia_name_container';
import BasicNumber from 'Registration/components/fields/basic_number';
import BasicText from 'Registration/components/fields/basic_text';
import ConditionBox from 'Registration/components/fields/condition_box';
import CurrencyField from 'Registration/components/fields/currency/currency_field';
import DateOfBirth from 'Registration/components/fields/date_of_birth';
import DepositLimit from 'Registration/components/fields/deposit_limit';
import Dropdown from 'Registration/components/fields/dropdown/dropdown';
import Email from 'Registration/components/fields/email';
import Gender from 'Registration/components/fields/gender';
import IdentityCardNumber from 'Registration/components/fields/identity_card_number';
import MarketingField from 'Registration/components/fields/marketing/marketing_field';
import NationalityRedux from 'Registration/components/fields/nationality/nationality_redux';
import OptInConditionBox from 'Registration/components/fields/marketing/opt_in_condition_box';
import Password from 'Registration/components/fields/password';
import PhoneNumber from 'Registration/components/fields/phone_number_redux';
import WarningMessage from 'Registration/components/fields/warning_message';

export default (type) => ({
  address: Address,
  address_finder: AddressFinder,
  asia_id_card_number: IdentityCardNumber,
  asia_name: AsiaName,
  currency: CurrencyField,
  deposit_limit: DepositLimit,
  dob: DateOfBirth,
  dropdown: Dropdown,
  email: Email,
  gender: Gender,
  marketing: MarketingField,
  marketing_asia: ConditionBox,
  marketing_germany: MarketingField,
  marketing_opt_in: OptInConditionBox,
  nationality: NationalityRedux,
  number: BasicNumber,
  password: Password,
  phone_number: PhoneNumber,
  privacy_policy: ConditionBox,
  terms_and_conditions: ConditionBox,
  terms_privacy_policy: ConditionBox,
  text: BasicText,
  warning_message: WarningMessage,
}[type]);
