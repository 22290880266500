import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import { compose } from 'underscore';
import { useFeature } from 'bv-hooks';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { getDefaultCountryId } from 'Registration/helpers/address';
import {
  getCountries,
  getDefaultCountrySetting,
  getHasAddressFinder,
  getAddressMapping,
} from 'Registration/selectors/registration_configurations';
import { getCurrentCountry } from 'Registration/selectors/registration_form';
import { setCurrentCountry } from 'Registration/ducks/form';
import FormField from 'Registration/components/form_kinds/registration/form_step/form_field';
import AddressFinder from './address_finder_container';
import AddressFinderV2 from './address_finder_v2_container';
import ManualAddress from './manual_address';

const ADDRESS_FIELDS = [
  'address1', 'address2', 'address3', 'address4', 'county_id', 'state_id', 'postcode',
];

const AddressContainer = ({
  validations,
  countries,
  defaultCountrySetting,
  hasAddressFinder,
  addressMapping,
  setCountry,
  countryId: reduxCountryId,
}) => {
  const [v2Enabled] = useFeature('address_finder_v2');

  const form = useForm();
  const { values } = useFormState();
  const [showAddressFinder, setShowAddressFinder] = useState(false);

  const { country: countryId } = values;

  const hasAddressInput = ADDRESS_FIELDS.some((field) => !!values[field]);

  const { fields, id: addressId } = addressMapping;
  const countryRef = useRef();

  const clearAddressData = () => {
    fields?.forEach((field) => {
      form.change(field, null);
      if (!showAddressFinder) form.resetFieldState(field);
    });
  };

  const renderManualAddress = ({ clearData }) => {
    if (clearData) clearAddressData();
    setShowAddressFinder(false);
  };

  useEffect(() => {
    if (!countryId) {
      const defaultCountry = String(getDefaultCountryId(countries, defaultCountrySetting));
      form.change('country', defaultCountry);
      setCountry(defaultCountry);
    }

    if (localStorage.getItem('registration_type') === 'paypal') {
      renderManualAddress({ clearData: false });
    }
  }, []);

  useEffect(() => {
    if (!hasAddressInput || countryRef.current) {
      clearAddressData();
      setShowAddressFinder(hasAddressFinder);
    }
    if (countryId) countryRef.current = countryId;
  }, [countryId]);

  useEffect(() => {
    if (!reduxCountryId && countryId) {
      setCountry(countryId);
    }
  }, [reduxCountryId, countryId]);

  const AddressFinderComponent = v2Enabled ? AddressFinderV2 : AddressFinder;

  return (
    <>
      <FormField
        key="country"
        name="country"
        label={t('account_form.country')}
        type="dropdown"
        validations={[{
          type: 'mandatory',
          errorMessage: t('javascript.account_form.errors.mandatory.country'),
        }]}
      />
      {showAddressFinder ? (
        <AddressFinderComponent
          renderManualAddress={renderManualAddress}
          changeInput={form.change}
        />
      ) : (
        <ManualAddress
          renderAddressFinder={() => setShowAddressFinder(true)}
          validations={validations}
          addressId={addressId}
          fields={fields}
          hasAddressFinder={hasAddressFinder}
        />
      )}
    </>
  );
};

AddressContainer.propTypes = {
  validations: PropTypes.instanceOf(Array).isRequired,
  countries: PropTypes.arrayOf(Object).isRequired,
  defaultCountrySetting: PropTypes.string.isRequired,
  hasAddressFinder: PropTypes.bool.isRequired,
  addressMapping: PropTypes.instanceOf(Object).isRequired,
  setCountry: PropTypes.func.isRequired,
  countryId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  countries: getCountries(state),
  defaultCountrySetting: getDefaultCountrySetting(state),
  hasAddressFinder: getHasAddressFinder(state),
  addressMapping: getAddressMapping(state),
  countryId: getCurrentCountry(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCountry: compose(dispatch, setCurrentCountry),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressContainer);
