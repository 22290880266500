import {
  useState, useEffect, useRef,
} from 'react';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'bv-components';
import ErrorList from './error_list';

const Password = ({
  input,
  label,
  meta: {
    error, active, touched, dirty, dirtySinceLastSubmit,
  },
  validatorResults,
  maxLength,
}) => {
  const form = useForm();
  const { submitErrors } = useFormState();
  const submitError = submitErrors && submitErrors[input.name];

  const [showPassword, setShowPassword] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current && (touched || dirty)) form.focus('password');
  }, [showPassword]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const onBlur = () => {
    // Required to allow a possible next button click to be handled, otherwise won't work
    setTimeout(input.onBlur, 100);
  };

  const eyeClick = () => setShowPassword(!showPassword);

  const showValidatorFeedback = () => {
    if (!dirtySinceLastSubmit && submitError) {
      return <span className="bvs-form-error-msg">{submitError}</span>;
    }
    if (active || (!active && touched && validatorResults.some((v) => !v.valid))) {
      return <ErrorList errorList={validatorResults.filter((v) => v.type !== 'maxSize')} />;
    }
    return null;
  };

  const onChange = ({ target }) => input.onChange(target.value);

  const inputFieldClassNames = classnames(
    'bvs-form-control', {
      error: (error && touched) || (submitError && !dirtySinceLastSubmit),
      active,
      'with-value': !!(input.value),
    },
  );

  return (
    <div className="bvs-form-group">
      <input
        {...input}
        type={showPassword ? 'text' : input.type}
        name={input.name}
        id={input.name}
        className={inputFieldClassNames}
        autoCapitalize="off"
        autoCorrect="off"
        autoComplete="new-password"
        maxLength={maxLength}
        onBlur={onBlur}
        onChange={onChange}
      />
      <label className="bvs-form-control-placeholder" htmlFor={input.name}>{label}</label>
      <Icon
        id={`eye-catcher-${showPassword ? 'active' : 'inactive'}`}
        onClick={eyeClick}
        className="account-form__eye-catcher-icon"
      />
      {showValidatorFeedback()}
    </div>
  );
};

Password.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  validatorResults: PropTypes.arrayOf(Object).isRequired,
  focusInput: PropTypes.func.isRequired,
  isChangePassword: PropTypes.bool.isRequired,
  rules: PropTypes.instanceOf(Object).isRequired,
  maxLength: PropTypes.number.isRequired,
};

export default Password;
