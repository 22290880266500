import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { OnOff } from 'bv-components';
import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';
import showMessage from 'Registration/components/fields/basic_error';
import { getDepositLimitOptions, getCurrencies } from 'Registration/selectors/registration_configurations';
import { fetchDepositLimitOptions } from 'Registration/ducks/configurations';

const DepositLimit = ({ input, meta: { touched, error } }) => {
  const [useExisting, setUseExisting] = useState(false);
  const dispatch = useDispatch();
  const { options, defaultValue } = useSelector(getDepositLimitOptions);
  const { values: { country, currency } } = useFormState();

  const currencies = useSelector(getCurrencies);
  const currencyISO = useMemo(() => {
    const selectedCurrency = currencies.find((c) => c.id === currency);
    return selectedCurrency ? selectedCurrency.iso_code : 'GBP';
  }, [currency]);

  // Reset value when destroyed
  useEffect(() => () => input.onChange(null), []);

  useEffect(() => {
    dispatch(fetchDepositLimitOptions(country, currency));
  }, [country]);

  useEffect(() => {
    if (options.includes(defaultValue)) {
      input.onChange(useExisting ? '' : defaultValue);
    }
  }, [options, defaultValue, useExisting]);

  if (!options.length) return null;

  return (
    <div className="regpath__rdl-container reg-path__title-own-card">
      <h4 className="reg-path__title-own-card">
        {t('account_form.monthly_deposit_limit.title')}
      </h4>
      <p className="account-form__msg">
        {t('account_form.monthly_deposit_limit.description')}
      </p>
      <div className="bvs-form-group">
        <select
          className="bvs-form-control"
          key={input.name}
          value={input.value}
          disabled={useExisting}
          {...input}
        >
          <option value="" disabled>
            {useExisting
              ? toCurrency(defaultValue, { currency: currencyISO, decimalPlaces: 0 })
              : t('deposit_limit.form.hint')}
          </option>
          {options.map((option) => (
            <option key={option} value={option}>
              {toCurrency(option, { currency: currencyISO, decimalPlaces: 0 })}
            </option>
          ))}
        </select>
      </div>
      <div className="account-form__toggle">
        <span>
          {t('account_form.monthly_deposit_limit.use_existing')}
        </span>
        <OnOff
          className="small"
          onClick={() => setUseExisting((state) => !state)}
          checked={useExisting}
        />
      </div>
      { showMessage(touched, error) }
    </div>
  );
};

DepositLimit.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default DepositLimit;
