import { useState } from 'react';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { sanitize } from 'dompurify';
import { t } from 'bv-i18n';

const MarketingField = (props) => {
  const { input } = props;
  const form = useForm();

  const [checked, setChecked] = useState(false);
  const checkboxStyles = classnames('account-form__checkbox', {
    active: !!input.value,
  });

  const onChange = (event) => {
    const { checked: isChecked } = event.target;
    form.change('marketing', isChecked);
    setChecked(isChecked);
  };

  return (
    <div className="regpath__marketing-container">
      <div className="checkbox-card__content">
        <div dangerouslySetInnerHTML={{ __html: sanitize(t(`javascript.account_form.${input.type}`)) }} />
      </div>
      <div className="bvs-checkbox-card__checkbox">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={`${input.name}_checkbox`} className={checkboxStyles}>
          <input type="checkbox" className="account-form__hidden" id={`${input.name}_checkbox`} onChange={onChange} checked={checked} />
        </label>
      </div>
    </div>
  );
};

MarketingField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
};
export default MarketingField;
