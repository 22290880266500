import { getWizards } from '../selectors/registration_configurations';

export const SET_CURRENT_WIZARD = 'registration/SET_CURRENT_WIZARD';
export const SET_CURRENT_JOURNEY = 'registration/SET_CURRENT_JOURNEY';
export const SET_CURRENT_STEP = 'registration/SET_CURRENT_STEP';
export const SET_CURRENT_COUNTRY = 'registration/SET_CURRENCT_COUNTRY';
export const SET_NEXT_STEP = 'registration/SET_NEXT_STEP';
export const SET_NEXT_WIZARD = 'registration/SET_NEXT_WIZARD';
export const MOVE_TO_FIELD_STEP = 'registration/MOVE_TO_FIELD_STEP';
export const SET_IS_DEPOSIT_LIMIT = 'registration/SET_IS_DEPOSIT_LIMIT';
export const RESET_FORM = 'registration/RESET_FORM';

export const setCurrentWizard = (wizard) => ({
  type: SET_CURRENT_WIZARD,
  wizard,
});

const setCurrentJourney = (journey) => ({
  type: SET_CURRENT_JOURNEY,
  journey,
});

export const setCurrentStep = (step) => ({
  type: SET_CURRENT_STEP,
  step,
});

export const setCurrentCountry = (country) => ({
  type: SET_CURRENT_COUNTRY,
  country,
});

export const setNextStep = () => ({
  type: SET_NEXT_STEP,
});

export const setNextWizard = (allWizards) => ({
  type: SET_NEXT_WIZARD,
  allWizards,
});

export const moveToField = (field) => ({
  type: MOVE_TO_FIELD_STEP,
  field,
});

export const setIsShowDepositLimit = (showDepositLimits) => ({
  type: SET_IS_DEPOSIT_LIMIT,
  showDepositLimits,
});

export const changeJourney = (journey, kind) => (dispatch, getState) => {
  dispatch(setCurrentJourney(journey));

  const { wizard } = getWizards(getState()).find((w) => w.journey === journey);
  const wizardKind = wizard.find((w) => w.kind === kind);
  dispatch(setCurrentWizard(wizardKind));
  dispatch(setCurrentStep(wizardKind.steps[0]));
};

export const resetForm = () => ({
  type: RESET_FORM,
});

export const initialState = {
  currentWizard: undefined,
  currentStep: undefined,
  showDepositLimits: false,
  currentJourney: 'standard',
  currentCountry: null,
};

const getFieldStep = (steps, searchField) => (
  steps.find((step) => step.fields.find((field) => field.name === searchField))
);

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_WIZARD:
      return {
        ...state,
        currentWizard: action.wizard,
      };

    case SET_NEXT_STEP: {
      const { currentStep } = state;
      const nextStep = state.currentWizard.steps
        .find((step) => step.number === currentStep.number + 1) || currentStep;
      return {
        ...state,
        currentStep: nextStep,
        lastStepVisitedNumber: nextStep.number,
      };
    }

    case SET_NEXT_WIZARD: {
      const journey = action.allWizards.find((w) => w.journey === state.currentJourney);
      const currentWizardIndex = journey.wizard.findIndex((wizard) => (
        wizard.kind === state.currentWizard.kind
      ));

      return {
        ...state,
        currentWizard: journey.wizard[currentWizardIndex + 1],
        currentStep: undefined,
      };
    }

    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.step,
        lastStepVisitedNumber: action.step.number,
      };

    case SET_CURRENT_COUNTRY:
      return {
        ...state,
        currentCountry: action.country,
      };

    case MOVE_TO_FIELD_STEP:
      return {
        ...state,
        currentStep: getFieldStep(state.currentWizard.steps, action.field),
      };

    case SET_IS_DEPOSIT_LIMIT:
      return {
        ...state,
        showDepositLimits: action.showDepositLimits,
      };

    case SET_CURRENT_JOURNEY:
      return {
        ...state,
        currentJourney: action.journey,
      };

    case RESET_FORM:
      return {
        ...state,
        currentWizard: undefined,
        currentStep: undefined,
        currentJourney: 'standard',
        lastStepVisitedNumber: 1,
      };

    default:
      return state;
  }
};
