import { t } from 'bv-i18n';
import { v } from 'bv';
import ConditionBox from '../condition_box';

const body = v('marketingOptIn')
  && (
  <>
    <p>
      { t('javascript.account_form.marketing_opt_in_description') }
    </p>
    <p>
      { v('marketingSpecialOptInSentence') ? t('javascript.registration_path.marketing.footer') : t('javascript.account_form.marketing_remember_preferences') }
    </p>
  </>
  );

const OptInConditionBox = (props) => (
  <ConditionBox {...props} body={body} />
);

export default OptInConditionBox;
