import { compose, isEmpty } from 'underscore';
import { getCookie } from 'bv';
import { getDefaultCountryID } from '../helpers/countries_helpers';
import {
  getCountries, getDefaults, getWizards, getProductId,
} from '../selectors/registration_configurations';
import * as api from '../api/fetch_configurations';

export const FETCH_CONFIGURATIONS_INIT = 'registration/FETCH_CONFIGURATIONS_INIT';
export const FETCH_CONFIGURATION_SUCCESS = 'registration/FETCH_CONFIGURATIONS_SUCCESS';
export const FETCH_DEPOSIT_LIMIT_OPTIONS_INIT = 'registration/FETCH_DEPOSIT_LIMIT_OPTIONS_INIT';
export const FETCH_DEPOSIT_LIMIT_OPTIONS_SUCCESS = 'registration/FETCH_DEPOSIT_LIMIT_OPTIONS_SUCCESS';

export const fetchConfigurationsInit = () => ({
  type: FETCH_CONFIGURATIONS_INIT,
});

export const fetchConfigurationsSuccess = (configurations) => ({
  type: FETCH_CONFIGURATION_SUCCESS,
  ...configurations,
});

const fetchDepositLimitOptionsInit = () => ({
  type: FETCH_DEPOSIT_LIMIT_OPTIONS_INIT,
});

const fetchDepositLimitOptionsSuccess = (options) => ({
  type: FETCH_DEPOSIT_LIMIT_OPTIONS_SUCCESS,
  options,
});

export const fetchConfigurations = () => (dispatch, getState) => {
  if (!isEmpty(getWizards(getState())) && getCookie('product_id') === getProductId(getState())) return Promise.resolve();

  dispatch(fetchConfigurationsInit());

  return api.fetchConfigurations()
    .then(compose(dispatch, fetchConfigurationsSuccess));
};

export const fetchDepositLimitOptions = (country, currency) => (dispatch, getState) => {
  const state = getState();
  const {
    currency: defaultCurrency,
    addressCountry,
  } = getDefaults(state);
  const defaultCountry = getDefaultCountryID(getCountries(state), addressCountry);

  dispatch(fetchDepositLimitOptionsInit());

  return api.fetchDepositLimitOptions(
    country || defaultCountry,
    currency || defaultCurrency,
  ).then(compose(dispatch, fetchDepositLimitOptionsSuccess));
};

export const initialState = {
  loading: true,
  countries: [],
  wizards: [],
  phoneNumberCountries: [],
  addressFinderCountries: [],
  currencies: [],
  images: [],
  defaults: {},
  features: {},
  productId: null,
  depositLimits: { options: [], defaultValue: null },
  addressMappings: [],
  passwordRules: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_CONFIGURATIONS_INIT:
      return initialState;

    case FETCH_CONFIGURATION_SUCCESS: {
      const { currencies, defaults } = action;
      const orderedCurrencies = defaults?.currency ? [
        currencies.find((cur) => cur.id === defaults.currency),
        ...currencies.filter((cur) => cur.id !== defaults.currency),
      ] : currencies;

      return {
        ...state,
        ...action,
        currencies: orderedCurrencies.filter(Boolean),
        productId: getCookie('product_id') || null,
        loading: false,
      };
    }
    case FETCH_DEPOSIT_LIMIT_OPTIONS_SUCCESS:
      return {
        ...state,
        depositLimits: action.options,
      };
    default:
      return state;
  }
};
