import { createSelector } from 'reselect';
import { getCurrentCountry } from './registration_form';

export const getRegistrationConfigurations = (state) => state.registrationConfigurations || {};

export const getLoading = createSelector(
  getRegistrationConfigurations,
  (registrationConfigurations) => registrationConfigurations.loading,
);

export const getWizards = createSelector(
  getRegistrationConfigurations,
  (registrationConfigurations) => registrationConfigurations.wizards || [],
);

export const getFeatures = createSelector(
  getRegistrationConfigurations,
  (registrationConfigurations) => registrationConfigurations.features,
);

export const getImages = createSelector(
  getRegistrationConfigurations,
  (registrationConfigurations) => registrationConfigurations.images,
);

export const getCountries = createSelector(
  getRegistrationConfigurations,
  (subState) => subState.countries,
);

export const getAddressFinderCountryIds = createSelector(
  getRegistrationConfigurations,
  (subState) => subState.addressFinderCountries,
);

export const getAddressFinderCountries = createSelector(
  getCountries,
  getAddressFinderCountryIds,
  (countries, addressFinderCountryIds) => (
    countries.filter((country) => addressFinderCountryIds.includes(country.id))
  ),
);

export const getAddressMappings = createSelector(
  getRegistrationConfigurations,
  (registrationConfigurations) => registrationConfigurations.addressMappings,
);

export const getCountryStates = createSelector(
  getRegistrationConfigurations,
  (registrationConfigurations) => registrationConfigurations.states,
);

export const getCountryCounties = createSelector(
  getRegistrationConfigurations,
  (registrationConfigurations) => registrationConfigurations.counties,
);

export const getCountryZones = createSelector(
  getRegistrationConfigurations,
  (registrationConfigurations) => registrationConfigurations.countryZones,
);

export const getRegistrationSettings = createSelector(
  getRegistrationConfigurations,
  (subState) => subState.settings,
);

export const getCurrencySettings = createSelector(
  getRegistrationConfigurations,
  (subState) => subState.currencySettings,
);

export const getPasswordRules = createSelector(
  getRegistrationConfigurations,
  (subState) => subState.passwordRules || {},
);

export const getProductId = (state) => getRegistrationConfigurations(state).productId;

export const getCurrencies = (state) => getRegistrationConfigurations(state).currencies;

export const getDefaults = (state) => getRegistrationConfigurations(state).defaults;

export const getDepositLimitOptions = (state) => getRegistrationConfigurations(state).depositLimits;

export const getDefaultMobileNumberCountry = createSelector(
  getDefaults,
  (defaults) => defaults.mobileNumberCountry,
);

export const getDefaultCountrySetting = createSelector(
  getDefaults,
  (defaults) => defaults.addressCountry,
);

export const getDefaultGender = createSelector(
  getDefaults,
  (defaults) => defaults.gender,
);

export const getPhoneNumberCountries = (state) => (
  getRegistrationConfigurations(state).phoneNumberCountries
);

export const getNationalities = (state) => getRegistrationConfigurations(state).nationalities;

export const getCountryIso = createSelector(
  getCountries,
  getCurrentCountry,
  (countries, countryId) => (
    countries.find((c) => c.id === countryId)?.isoCode
  ),
);

export const getHasAddressFinder = createSelector(
  getAddressFinderCountryIds,
  getCurrentCountry,
  getCountryIso,
  (addressFinderCountryIds, countryId, countryIso) => (
    window.pca?.countries.some((c) => c.iso2 === countryIso)
      && addressFinderCountryIds.includes(countryId)
  ),
);
export const getAddressMapping = createSelector(
  getAddressMappings,
  getCurrentCountry,
  (addressMappings, countryId) => (
    addressMappings.find(
      (mapping) => mapping.countryIds.includes(countryId),
    ) || {}
  ),
);

export const getCountryZonesBySelectedCountry = createSelector(
  getRegistrationConfigurations,
  getCurrentCountry,
  (configs, country) => (
    configs.counties[country] || configs.states[country] || []
  ),
);

const getCurrencySetting = (settings, country) => (
  settings.find((s) => s.country.toUpperCase() === country)
);

export const getCurrencySettingsByCountry = createSelector(
  getCurrencySettings,
  getCountries,
  getCurrentCountry,
  (currencySettings, countries, countryId) => {
    const country = countries.find((c) => c.id === countryId);
    if (!country) return getCurrencySetting(currencySettings, 'DEFAULT') || {};

    const countrySetting = getCurrencySetting(currencySettings, country.isoCode.toUpperCase());
    if (countrySetting) return countrySetting;

    return getCurrencySetting(currencySettings, 'DEFAULT') || {};
  },
);

export const getFilteredCurrencies = createSelector(
  getCurrencySettingsByCountry,
  getCurrencies,
  (currencySetting, currencies) => (
    currencies.filter((c) => (
      c.id !== currencySetting.firstCurrency && c.id !== currencySetting.secondCurrency
    ))
  ),
);

export const getFirstCurrency = createSelector(
  getCurrencySettingsByCountry,
  getCurrencies,
  (currencySetting, currencies) => (
    currencies.find((c) => c.id === currencySetting.firstCurrency)
  ),
);

export const getSecondCurrency = createSelector(
  getCurrencySettingsByCountry,
  getCurrencies,
  (currencySetting, currencies) => (
    currencies.find((c) => c.id === currencySetting.secondCurrency)
  ),
);
