import { v as bvVar, requireScript, requireStyle } from 'bv';
import { guard } from 'bv-services/features';
import { pick, flatten } from 'underscore';

const scriptDependencies = {
  addressFinder: window.JSBundles.addressFinderUrl,
  paypalSignup: [bvVar('braintreeLibUrl'), bvVar('paypalLibraryUrl')],
};

const styleDependencies = {
  addressFinder: window.CSSBundles.addressFinder,
};

const featureFlipperDependencies = [{
  flipper: 'iovation',
  script: window.JSBundles.IovationLoader,
}];

const getDependencies = (features, dependencies) => (
  flatten(Object.values(pick(dependencies, (value, key) => features[key])))
);

export const loadRequiredDependencies = (features) => ([
  ...getDependencies(features, scriptDependencies).map(requireScript),
  ...getDependencies(features, styleDependencies).map(requireStyle),
]);

export const loadFeatureFlipperDependencies = () => (
  featureFlipperDependencies.map((feature) => (
    guard(feature.flipper, () => requireScript(feature.script))
  ))
);
