import { getJSON } from 'bv-fetch';

export const fetchConfigurations = () => getJSON('/api/accounts/new').catch(() => ({
  addressMappings: null,
  countries: null,
  currencies: null,
  wizards: null,
  nationalities: null,
  passwordRules: null,
}));

export const fetchDepositLimitOptions = (countryId, currencyId) => getJSON(
  '/api/deposit_limits/options',
  { country_id: countryId, currency_id: currencyId },
).catch(() => ({
  options: [],
  defaultValue: null,
}));
