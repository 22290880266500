import { useState } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v as bvVar } from 'bv';
import { password as passwordValidation } from 'validations';
import PasswordField from './password_field';

const Password = ({
  isChangePassword,
  rules,
  ...rest
}) => {
  const [validatorResults, setValidatorResults] = useState([]);
  const validationFunction = passwordValidation(rules);

  const validator = (value) => {
    const validatedFields = validationFunction(value);
    setValidatorResults(validatedFields);
    return validatedFields.every((validatedField) => validatedField.valid)
      ? undefined
      : validatedFields;
  };

  const maxLength = rules.maxSize;
  const pwdCard = bvVar('regPathCards').password;
  const useCard = pwdCard[bvVar('brandId')] !== undefined ? pwdCard[bvVar('brandId')] : pwdCard.default;
  const wrapperClass = classnames({
    'bvs-form-group': !useCard || isChangePassword,
    'bvs-card': useCard && !isChangePassword,
  });

  return (
    <div className={wrapperClass}>
      <Field
        validate={validator}
        validatorResults={validatorResults}
        component={PasswordField}
        maxLength={maxLength}
        type="password"
        {...rest}
      />
    </div>
  );
};

Password.propTypes = {
  isChangePassword: PropTypes.bool.isRequired,
  rules: PropTypes.instanceOf(Object).isRequired,
};

export default Password;
