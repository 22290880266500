import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { t, locale } from 'bv-i18n';
import { notify } from 'bv-error-boundary';
import { transformAddress } from 'Registration/api/address_finder';
import {
  getAddressFinderCountries,
  getRegistrationSettings,
  getCountryIso,
} from 'Registration/selectors/registration_configurations';
import { getCurrentCountry } from 'Registration/selectors/registration_form';
import FormField from 'Registration/components/form_kinds/registration/form_step/form_field';

const AddressFinderContainer = ({
  renderManualAddress,
  changeInput,
}) => {
  const countryId = useSelector(getCurrentCountry);
  const countries = useSelector(getAddressFinderCountries);
  const settings = useSelector(getRegistrationSettings);
  const countryIso = useSelector(getCountryIso);

  const [hasAddressControlError, setHasAddressControlError] = useState(false);

  useEffect(() => {
    const fields = [
      { element: 'address_search', field: '' },
    ];

    const options = {
      key: settings.loqateValidatorApiKey,
      culture: locale().split('-')[1],
      countries: {
        codesList: countries.map((c) => c.isoCode).toString(),
      },
      bar: {
        visible: false,
      },
      search: {
        countries: countryIso,
      },
      showCountry: false,
      showLogo: false,
      logoLink: false,
    };

    const addressControl = new window.pca.Address(fields, options);

    addressControl.listen('populate', (address) => {
      transformAddress(countryId, address).then((addressFields) => {
        Object.entries(addressFields).forEach(([key, value]) => {
          changeInput(key, value);
        });
        renderManualAddress({ clearData: false });
      });
    });

    addressControl.listen('results', () => setHasAddressControlError(false));

    addressControl.listen('error', (message) => {
      setHasAddressControlError(true);
      notify(new Error(message), options);
    });
  }, [countryIso]);

  return (
    <>
      <FormField
        key="address_finder"
        name="address_finder"
        label="address_finder"
        type="address_finder"
        validations={[{
          type: 'mandatory',
          errorMessage: t('javascript.account_form.errors.mandatory.address'),
        }]}
        hasAddressControlError={hasAddressControlError}
      />
      <div className="cta__wrapper">
        <span className="account-form__field-text cta__text">
          { t('javascript.account_form.enter_address_manually') }
        </span>
        <span
          className="bvs-button-card cta__btn"
          onClick={() => renderManualAddress({ clearData: true })}
        >
          { t('javascript.account_form.enter_address_manually_cta') }
        </span>
      </div>
    </>
  );
};

AddressFinderContainer.propTypes = {
  renderManualAddress: PropTypes.func.isRequired,
  changeInput: PropTypes.func.isRequired,
};

export default AddressFinderContainer;
